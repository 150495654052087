<template>
  <v-responsive>
    <SubMenuCustomerMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuCustomerMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuCustomer></SubMenuCustomer>
        </v-col>

        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>




          






              <v-row class="my-0 mx-0" style="background: #fff">


                <v-col cols="12" >
                  <v-card class="d-flex justify-center mt-5" flat>
                    <v-img
                      src="@/assets/customer_banner.png"
                    ></v-img>
                  </v-card>
                </v-col>

                <v-col cols="12" >
              <h3>자주찾는 질문</h3>
              <ContentFaq></ContentFaq>
                </v-col>

                <v-col  xs="12"  sm="12" md="6" >      
                  <!--당첨자목록 S-->
                      <v-row class="py-1">
                        <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;">
                          
                             <strong style='color:#00234d;margin-right:5px;'>당첨번호 </strong> 분석시스템
                        </v-col>
                        <v-col>
                          
                        </v-col>

                      </v-row>
                      <v-img
                        alt="Vuetify Logo"
                        class="shrink mt-1"
                        contain
                        src="@/assets/tmp_sub_banner1.png"
                        full-width
                      />
                  <!--당첨자목록 E -->
                </v-col>
                <v-col  xs="12"  sm="12" md="6">      
                  <!-- 조합도구 S -->  
                      <v-row class="py-1">
                        <v-col class="d-flex align-center" style="color:#333;font-size:17px;font-weight:bold;">
                          
                             <strong style='color:#00234d;margin-right:5px;'>맞춤전략 </strong>  조합 도구
                        </v-col>
                        <v-col>
                          
                        </v-col>

                      </v-row>
                      <v-img
                        alt="Vuetify Logo"
                        class="shrink mt-1"
                        contain
                        src="@/assets/tmp_sub_banner2.png"
                        full-width
                      />
                  <!--조합도구 E -->
                </v-col>
              </v-row>

<!--
          <v-sheet min-height="58vh">


            <v-card class="mx-auto mt-6">
          

              <v-card-title class="font-weight-bold"> 회원 가입 이벤트 </v-card-title>

              <v-card-subtitle> 일주일에 5개의 무료 추천번호를 받을 수 있습니다.</v-card-subtitle>

              <v-card-actions>
                <v-btn color="orange lighten-2"  @click="show = !show" text> 혜택 보기 </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show = !show">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text>
                    이벤트 혜택 안내
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

          </v-sheet>-->

        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Customer",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "고객센터" },
      { text: "고객지원" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
      show: false,
  }),
};
</script>
<style></style>
